<template>
  <div class="expenses__wrapper">
    <Loading v-if="isLoading" />
    <div v-if="!isLoading" class="expenses" id="expenses-table">
      <StickyHeader>
        <div
          ref="tableHeaderRef"
          class="expenses__head"
          :style="{
            'grid-template-columns': `300px repeat(${
              getSelectedInterval.amount + 1
            }, 50px)`,
          }"
        >
          <ExpensesHeadCell :isItEmptyCell="true" :numberOfColumn="0" />
          <ExpensesHeadCell :isItSumCell="true" :numberOfColumn="1" />
          <ExpensesHeadCell
            :key="'header-day-' + n + 1"
            v-for="n in getSelectedInterval.amount"
            :numberOfColumn="n + 1"
          />
        </div>
      </StickyHeader>
      <template
        v-for="(profilesData, profilesIndex) of profilesModeOn
          ? expensesList
          : [{}]"
      >
        <SpoilerRow
          :key="'pre-profile-' + profilesData.id + `-${profilesIndex}`"
          v-if="profilesModeOn"
          :rowData="{
            sum_plan_hours: profilesData.customers.reduce(
              (acc, customer) => acc + customer.sum_plan_hours,
              0
            ),
            sum_actual_hours: profilesData.customers.reduce(
              (acc, customer) => acc + customer.sum_actual_hours,
              0
            ),
            name: `${profilesData.last_name} ${profilesData.first_name}`,
          }"
          :spoilerType="'pre-profile'"
          :spoilerName="`${profilesModeOn ? `${profilesData.id}` : ''}`"
          :customCellsData="(n) => getTrackedHours(n, profilesData.id)"
        />
        <template
          v-for="customerData of profilesModeOn
            ? profilesData.customers
            : expensesList"
        >
          <SpoilerRow
            :key="'customer-' + customerData.id + `-${profilesData.id}`"
            :rowData="customerData"
            :spoilerType="'customer'"
            :spoilerName="`${profilesModeOn ? `${profilesData.id}--` : ''}${
              customerData.name
            }`"
          />
          <template v-for="projectData of customerData.projects">
            <SpoilerRow
              :key="'project-' + projectData.id + `-${profilesData.id}`"
              :rowData="projectData"
              :spoilerType="'project'"
              :spoilerName="`${profilesModeOn ? `${profilesData.id}--` : ''}${
                customerData.name
              }--${projectData.name}`"
            />
            <template v-for="assignData of projectData.assignments">
              <SpoilerRow
                :key="'activity-' + assignData.id + `-${profilesData.id}`"
                v-if="!!assignData.activity"
                :rowData="{
                  ...assignData,
                  name: assignData.activity.activity,
                }"
                :spoilerType="'activity'"
                :spoilerName="`${profilesModeOn ? `${profilesData.id}--` : ''}${
                  customerData.name
                }--${projectData.name}--${assignData.activity.activity}`"
              />
              <template
                v-for="(
                  subAssignData, subAssignIndex
                ) of assignData.sub_assignments"
              >
                <SpoilerRow
                  :key="'profile-' + subAssignData.id + `-${subAssignIndex}`"
                  v-if="!profilesModeOn"
                  :rowData="{
                    ...subAssignData,
                    name: subAssignData.profile.full_name,
                  }"
                  :spoilerType="'profile'"
                  :spoilerName="`${
                    profilesModeOn ? `${profilesData.id}--` : ''
                  }${customerData.name}--${projectData.name}--${
                    assignData.activity.activity
                  }--${subAssignData.profile.full_name}`"
                />
                <template v-for="task of subAssignData.tasks">
                  <ExpensesRow
                    :parentSpoilerName="`${
                      profilesModeOn ? `${profilesData.id}--` : ''
                    }${customerData.name}--${projectData.name}--${
                      assignData.activity.activity
                    }--${subAssignData.profile.full_name}`"
                    :key="`${customerData.name}--${projectData.name}--${assignData.activity.activity}--${subAssignData.profile.full_name}--${task.id}`"
                  >
                    <ExpensesCell
                      :numberOfColumn="0"
                      :style="{ 'padding-left': '26px' }"
                      :is-editable="true"
                      :is-general-cell="true"
                      :data="task.name"
                      :taskId="task.id"
                      :isTimeTrackAllowed="true"
                    />
                    <ExpensesCell
                      :numberOfColumn="1"
                      :data="getSumOfTaskHours(task)"
                      style="justify-content: center"
                    />
                    <template v-for="numberOfCell in intervalData.amount">
                      <ExpensesCell
                        :isTimeTrackAllowed="true"
                        :key="
                          'cell-number-' +
                          task.id +
                          '_' +
                          numberOfCell +
                          '_' +
                          getSelectedInterval.amount
                        "
                        :is-editable="true"
                        :is-general-cell="false"
                        :isItToday="isDayToday(getDayNumber(numberOfCell - 1))"
                        :data="
                          getActualHours(
                            task.time_entries,
                            getDayNumber(numberOfCell - 1)
                          )
                        "
                        :taskId="task.id"
                        :numberOfColumn="numberOfCell + 1"
                        :isItWeekendDay="isItWeekendDay(numberOfCell)"
                        :isItLastCell="numberOfCell === intervalData.amount"
                        style="justify-content: center"
                      />
                    </template>
                  </ExpensesRow>
                </template>
              </template>
            </template>
          </template>
        </template>
      </template>
    </div>
    <TooltipCloud />
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import ExpensesCell from "./ViewExpensesCell";
import ExpensesRow from "./ExpensesRow";
import StickyHeader from "@/components/StickyHeader.vue";
import Loading from "@/components/Loading";
import SpoilerRow from "@/components/expenses/ViewSpoilerRow";
import TooltipCloud from "@/components/expenses/TooltipCloud";
import ExpensesHeadCell from "@/components/expenses/ExpensesHeadCell";

dayjs.extend(isoWeek);

export default {
  name: "expenses-table",
  components: {
    ExpensesHeadCell,
    TooltipCloud,
    SpoilerRow,
    Loading,
    ExpensesCell,
    StickyHeader,
    ExpensesRow,
  },
  props: {
    expensesList: {
      type: Array,
      default() {
        return [];
      },
    },
    intervalData: {
      type: Object,
    },
    readonly: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isLoading: {
      type: Boolean,
      default: () => true,
    },
    profilesModeOn: {
      type: Boolean,
      default: () => false,
    },
  },
  updated() {
    this.setReadonly(this.readonly);
    let closedSpoilerIds = [];
    this.setClosedRows(closedSpoilerIds);
  },
  computed: {
    ...mapState({
      currentDaysCalendar: (state) =>
        state.productionCalendarStore.currentDaysCalendar,
      focusedColumnNumber: (state) => state.expensesStore.focusedColumnNumber,
      expensesHiddenTasks: (state) => state.expensesStore.expensesHiddenTasks,
      isHiddenRowsVisible: (state) => state.expensesStore.isHiddenRowsVisible,
      isTimerActive: (state) => state.expensesStore.isTimerActive,
      timer: (state) => state.expensesStore.timer,
      userData: (state) => state.userStore.me,
      currentMonthExpensesList: (state) =>
        state.expensesStore.currentMonthExpensesList,
    }),
    ...mapGetters({
      getCurrentTooMuchTrackedDays:
        "expensesStore/getCurrentTooMuchTrackedDays",
      getCurrentTrackedDays: "expensesStore/getCurrentTrackedDays",
      getMyDate: "userStore/getMyDate",
      getSelectedInterval: "expensesStore/getSelectedInterval",
      getFocusedCellPosition: "expensesStore/getFocusedCellPosition",
    }),
    isSelectedIntervalIncludeCurrentDay() {
      const { since, until } = this.getSelectedInterval;
      const startDate = dayjs(since).startOf("month");
      const endDate = dayjs(until).endOf("month");
      const currentDate = this.getMyDate.toDate();
      return (
        currentDate >= startDate.toDate() && currentDate <= endDate.toDate()
      );
    },
  },
  methods: {
    ...mapActions({
      createNewTask: "expensesStore/createNewTask",
      findProject: "projectsStore/findProject",
      startTimer: "expensesStore/startTimer",
      deleteTask: "expensesStore/deleteTask",
    }),
    ...mapMutations({
      setFocusedColumnNumber: "expensesStore/setFocusedColumnNumber",
      setFocusCreatedInput: "expensesStore/setFocusCreatedInput",
      setReadonly: "expensesStore/setReadonly",
      setClosedRows: "expensesStore/setClosedRows",
      setIsTimerActive: "expensesStore/setIsTimerActive",
      setTimerIntervalId: "expensesStore/setTimerIntervalId",
    }),
    getDayNumber(n) {
      return dayjs(this.intervalData.since).add(n, "day").date();
    },
    isItWeekendDay(n) {
      const currentDayNumber = this.getDayNumber(n - 1);
      const dayType = this.currentDaysCalendar.find(
        (day) => day.number === currentDayNumber
      )?.day_type;
      return dayType === "DAY_OFF";
    },
    getActualHours(timeEntries, dayNumber) {
      const currentTimeEntriesItem = timeEntries.find(
        (timeEntriesItem) => timeEntriesItem.pc_day.number === dayNumber
      );
      return currentTimeEntriesItem
        ? currentTimeEntriesItem.actual_hours?.toString()
        : "";
    },
    getPCDayId(dayNumber) {
      return this.currentDaysCalendar.find((day) => day.number === dayNumber)
        ?.id;
    },
    getSumOfTaskHours(task) {
      return task.time_entries
        .reduce((acc, timeEntriesItem) => {
          return acc + timeEntriesItem.actual_hours;
        }, 0)
        .toFixed(1)
        .toString();
    },
    getTrackedHours(dayNumber, profileId = 0) {
      let trackedHours = "";

      const currentTrackedDay =
        !!this.getCurrentTrackedDays.length &&
        this.getCurrentTrackedDays.find(
          (trackedDay) =>
            trackedDay.dayId ===
            this.getPCDayId(this.getDayNumber(dayNumber - 1))
        );
      if (currentTrackedDay && currentTrackedDay.actual_hours) {
        if (!profileId) {
          trackedHours = `${currentTrackedDay.actual_hours.toFixed(1)}`;
        } else {
          const currentProfileTrackedDay = currentTrackedDay.profileIds.find(
            (trackedProfile) => trackedProfile.profile === profileId
          );
          if (currentProfileTrackedDay) {
            trackedHours = `${currentProfileTrackedDay.actual_hours.toFixed(
              1
            )}`;
          }
        }
      }

      return trackedHours;
    },
    isDayToday(dayNumber) {
      return (
        this.isSelectedIntervalIncludeCurrentDay &&
        (dayNumber === this.getMyDate.date() ||
          dayNumber - 1 === this.getMyDate.date())
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.expenses__wrapper {
  position: relative;
}

.expenses {
  width: min-content;
  margin-right: 50px;
  border: 1px solid var(--primary-bg-color);
  padding: 1px;
  position: relative;
}

.expenses__head {
  background-color: var(--primary-bg-color);
  color: #ffffff;
  text-align: center;
  font-weight: 400;
  display: grid;
  //border: 1px solid var(--primary-bg-color);
}
</style>

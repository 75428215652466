var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"expenses__body-cell",class:{
    'expenses__body-cell--non-editable': _vm.isGeneralCell,
    'expenses__body-cell--today': _vm.isItToday,
    'expenses__body-cell--monday': _vm.getIsItMonday,
    'expenses__body-cell--workday': !_vm.isItWeekendDay,
    'expenses__body-cell--weekend-day': _vm.isItWeekendDay,
    'expenses__body-cell--last-cell': _vm.isItLastCell,
    'expenses__body-cell--sticky':
      _vm.numberOfColumn === 0 || _vm.numberOfColumn === 1,
  },style:({
    left:
      _vm.numberOfColumn === 0 ? '180px' : _vm.numberOfColumn === 1 ? '480px' : '0',
  })},[[(!this.changeTaskModeOn)?_c('div',{staticClass:"expenses__body-cell-data expenses__body-cell-data--name",on:{"click":_vm.cellClick}},[(_vm.isOpen !== null)?_c('span',{staticClass:"expenses__body-cell-spoiler-icon"},[_c('SpoilerIcon',{attrs:{"styleObject":{
            transform: _vm.isOpen ? 'rotate(0)' : 'rotate(-90deg)',
          }}})],1):_vm._e(),(_vm.isGeneralCell && !!_vm.data)?_c('Tooltip',{attrs:{"tooltipData":_vm.data,"tooltip-background":_vm.isEditable ? '#fff' : '#ccc',"classNames":{ 'expenses__body-cell-data--text': true }}}):_c('p',{staticClass:"expenses__body-cell-data--text"},[_vm._v(_vm._s(_vm.data))]),(_vm.projectDescription)?_c('div',{staticClass:"expenses__body-cell-data--description"},[(!!_vm.data)?_c('Tooltip',{attrs:{"tooltipData":_vm.projectDescription,"tooltip-background":'#fff',"withIcon":true}}):_vm._e()],1):_vm._e()],1):_vm._e(),(_vm.hoursRatio)?_c('div',{staticClass:"expenses__body-cell-data expenses__body-cell-data--hours"},[_vm._v(" "+_vm._s(_vm.hoursRatio)+" ")]):_vm._e()],(_vm.numberOfColumn === 1)?_c('div',{staticClass:"expenses__body-cell--sticky-border"}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
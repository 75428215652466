var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"expenses__wrapper"},[(_vm.isLoading)?_c('Loading'):_vm._e(),(!_vm.isLoading)?_c('div',{staticClass:"expenses",attrs:{"id":"expenses-table"}},[_c('StickyHeader',[_c('div',{ref:"tableHeaderRef",staticClass:"expenses__head",style:({
          'grid-template-columns': `300px repeat(${
            _vm.getSelectedInterval.amount + 1
          }, 50px)`,
        })},[_c('ExpensesHeadCell',{attrs:{"isItEmptyCell":true,"numberOfColumn":0}}),_c('ExpensesHeadCell',{attrs:{"isItSumCell":true,"numberOfColumn":1}}),_vm._l((_vm.getSelectedInterval.amount),function(n){return _c('ExpensesHeadCell',{key:'header-day-' + n + 1,attrs:{"numberOfColumn":n + 1}})})],2)]),_vm._l((_vm.profilesModeOn
        ? _vm.expensesList
        : [{}]),function(profilesData,profilesIndex){return [(_vm.profilesModeOn)?_c('SpoilerRow',{key:'pre-profile-' + profilesData.id + `-${profilesIndex}`,attrs:{"rowData":{
          sum_plan_hours: profilesData.customers.reduce(
            (acc, customer) => acc + customer.sum_plan_hours,
            0
          ),
          sum_actual_hours: profilesData.customers.reduce(
            (acc, customer) => acc + customer.sum_actual_hours,
            0
          ),
          name: `${profilesData.last_name} ${profilesData.first_name}`,
        },"spoilerType":'pre-profile',"spoilerName":`${_vm.profilesModeOn ? `${profilesData.id}` : ''}`,"customCellsData":(n) => _vm.getTrackedHours(n, profilesData.id)}}):_vm._e(),_vm._l((_vm.profilesModeOn
          ? profilesData.customers
          : _vm.expensesList),function(customerData){return [_c('SpoilerRow',{key:'customer-' + customerData.id + `-${profilesData.id}`,attrs:{"rowData":customerData,"spoilerType":'customer',"spoilerName":`${_vm.profilesModeOn ? `${profilesData.id}--` : ''}${
            customerData.name
          }`}}),_vm._l((customerData.projects),function(projectData){return [_c('SpoilerRow',{key:'project-' + projectData.id + `-${profilesData.id}`,attrs:{"rowData":projectData,"spoilerType":'project',"spoilerName":`${_vm.profilesModeOn ? `${profilesData.id}--` : ''}${
              customerData.name
            }--${projectData.name}`}}),_vm._l((projectData.assignments),function(assignData){return [(!!assignData.activity)?_c('SpoilerRow',{key:'activity-' + assignData.id + `-${profilesData.id}`,attrs:{"rowData":{
                ...assignData,
                name: assignData.activity.activity,
              },"spoilerType":'activity',"spoilerName":`${_vm.profilesModeOn ? `${profilesData.id}--` : ''}${
                customerData.name
              }--${projectData.name}--${assignData.activity.activity}`}}):_vm._e(),_vm._l((assignData.sub_assignments),function(subAssignData,subAssignIndex){return [(!_vm.profilesModeOn)?_c('SpoilerRow',{key:'profile-' + subAssignData.id + `-${subAssignIndex}`,attrs:{"rowData":{
                  ...subAssignData,
                  name: subAssignData.profile.full_name,
                },"spoilerType":'profile',"spoilerName":`${
                  _vm.profilesModeOn ? `${profilesData.id}--` : ''
                }${customerData.name}--${projectData.name}--${
                  assignData.activity.activity
                }--${subAssignData.profile.full_name}`}}):_vm._e(),_vm._l((subAssignData.tasks),function(task){return [_c('ExpensesRow',{key:`${customerData.name}--${projectData.name}--${assignData.activity.activity}--${subAssignData.profile.full_name}--${task.id}`,attrs:{"parentSpoilerName":`${
                    _vm.profilesModeOn ? `${profilesData.id}--` : ''
                  }${customerData.name}--${projectData.name}--${
                    assignData.activity.activity
                  }--${subAssignData.profile.full_name}`}},[_c('ExpensesCell',{style:({ 'padding-left': '26px' }),attrs:{"numberOfColumn":0,"is-editable":true,"is-general-cell":true,"data":task.name,"taskId":task.id,"isTimeTrackAllowed":true}}),_c('ExpensesCell',{staticStyle:{"justify-content":"center"},attrs:{"numberOfColumn":1,"data":_vm.getSumOfTaskHours(task)}}),_vm._l((_vm.intervalData.amount),function(numberOfCell){return [_c('ExpensesCell',{key:'cell-number-' +
                        task.id +
                        '_' +
                        numberOfCell +
                        '_' +
                        _vm.getSelectedInterval.amount,staticStyle:{"justify-content":"center"},attrs:{"isTimeTrackAllowed":true,"is-editable":true,"is-general-cell":false,"isItToday":_vm.isDayToday(_vm.getDayNumber(numberOfCell - 1)),"data":_vm.getActualHours(
                          task.time_entries,
                          _vm.getDayNumber(numberOfCell - 1)
                        ),"taskId":task.id,"numberOfColumn":numberOfCell + 1,"isItWeekendDay":_vm.isItWeekendDay(numberOfCell),"isItLastCell":numberOfCell === _vm.intervalData.amount}})]})],2)]})]})]})]})]})]})],2):_vm._e(),_c('TooltipCloud')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="container">
    <div class="expenses__title">
      <h2 class="expenses__title--text">Трудозатраты</h2>
    </div>
    <template v-if="!error">
      <ExpensesIntervalSelect
        :lastPlanningDate="lastPlanningDate"
        :firstPlanningDate="firstPlanningDate"
        @getInterval="getInterval"
        style="position: sticky; left: 190px"
      />
      <div class="expenses-view-actions">
        <div class="expenses-view-actions__left">
          <CheckboxFilterModal
            v-model="activeProjectsIds"
            title="Выбор проекта"
            :list="getProjectsList"
            :with-active-items-number="activeCheckboxFilter === 'projects'"
            :with-approve-button="true"
            :withSearch="true"
            class="expenses-view-actions__filter"
            @approve="loadProjects('projects')"
          />
          <p>ИЛИ</p>
          <CheckboxFilterModal
            v-model="activeStaffIds"
            title="Выбор сотрудника"
            :list="getProfilesList"
            :with-active-items-number="activeCheckboxFilter === 'profiles'"
            :with-approve-button="true"
            :withSearch="true"
            class="expenses-view-actions__filter"
            @approve="loadProjects('profiles')"
          />
        </div>
        <div class="expenses-view-actions__right" v-if="hasDownloadAccess">
          <Select
            ref="selectRef"
            default="Выбор проекта для отчета"
            :options="filteredReportList"
            showModel="name"
            :only-one-line="true"
            :mode="['default', 'with-search']"
            searchPlaceholder="Поиск"
            customWidth="300px"
            @selectOption="setReportProjectName"
            @searchValue="searchReportProjects"
          />
          <Button
            :disabled="!activeProjectNameForReport"
            :theme="'common'"
            @click="uploadReport"
          >
            Скачать отчёт
          </Button>
        </div>
      </div>
      <MyExpensesTable
        :isLoading="isLoading"
        :intervalData="intervalData"
        :expensesList="getExpensesViewList"
        :profilesModeOn="activeCheckboxFilter === 'profiles'"
        :readonly="true"
      />
    </template>
    <div v-else>
      {{ error }}
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import MyExpensesTable from "../../components/expenses/ViewExpensesTable";
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import ExpensesIntervalSelect from "../../components/expenses/ExpensesIntervalSelect";
import CheckboxFilterModal from "@/components/CheckboxFilterModal";
import { saveAs } from "file-saver";
import Button from "@/components/Button";
import Select from "@/components/Select.vue";
import { ROLES } from "@/assets/enums";

export default {
  components: {
    Button,
    CheckboxFilterModal,
    ExpensesIntervalSelect,
    MyExpensesTable,
    Select,
  },
  data() {
    return {
      intervalData: {
        amount: 0,
        since: "",
        until: "",
      },
      activeProjectNameForReport: "",
      activeCheckboxFilter: null,
      stateFromFilter: null, // указывает данные по какому фильтру были загружены последними
      activeProjectsIds: [],
      activeStaffIds: [],
      isLoading: true,
      lastTicket: null,
      isAllFiltersSet: false,
      searchingReportProject: "",
    };
  },
  async created() {
    await this.loadPlanningsList();
  },
  destroyed() {
    this.setExpensesViewList({ results: [] });
  },
  watch: {
    activeCheckboxFilter(checkboxFilter) {
      if (checkboxFilter === "projects") {
        this.activeStaffIds = [];
      } else {
        this.activeProjectsIds = [];
      }
    },
    activeProjectsIds(list) {
      if (this.activeCheckboxFilter === "projects") {
        if (list.length === this.getProjectsList.length) {
          this.isAllFiltersSet = true;
        } else {
          this.isAllFiltersSet = false;
        }
      }
    },
    activeStaffIds(list) {
      if (this.activeCheckboxFilter === "profiles") {
        if (list.length === this.getProfilesList.length) {
          this.isAllFiltersSet = true;
        } else {
          this.isAllFiltersSet = false;
        }
      }
    },
    getProjectsList(list) {
      if (this.isAllFiltersSet && this.activeCheckboxFilter === "projects") {
        this.activeProjectsIds = list.map((project) => project.id);
      }
    },
    getProfilesList(list) {
      if (this.isAllFiltersSet && this.activeCheckboxFilter === "profiles") {
        this.activeStaffIds = list.map((employee) => employee.id);
      }
    },
  },
  methods: {
    setActiveFilter(filter) {
      this.activeCheckboxFilter = filter;
    },
    async getInterval(data) {
      if (!data.length) {
        return;
      }
      this.$refs?.selectRef?.reset();
      this.isLoading = true;

      if (!this.getCalendarYears.length) {
        await this.loadCalendarYears();
      }

      let currentPartOfData = 0;
      if (data[1]) {
        //todo проверять, на какой части разрывной недели мы находимся и оттуда брать месяцы
        currentPartOfData = 1;
      }

      const currentYearNumber = parseInt(
        data[currentPartOfData].since.split("-")[0]
      );
      const currentYearId = this.getCalendarYears.find(
        (year) => year.number === currentYearNumber
      ).id;
      await this.loadCalendarMonths(currentYearId);

      const currentMonthNumber = parseInt(
        data[currentPartOfData].since.split("-")[1]
      );
      const month_id = this.getCalendarMonths.find(
        (month) => month.name === currentMonthNumber
      ).id;
      await this.loadCalendarDays({ month_id });

      if (data.length) {
        if (data.length === 2) {
          const [firstPart, secondPart] = data;
          this.intervalData = {
            amount: 7,
            since: firstPart.since,
            until: secondPart.until,
          };
        } else {
          this.intervalData = data[0];
        }
        for (const [index, interval] of data.entries()) {
          if (index > 0) {
            interval.isPart = true;
          }
          this.activeProjectNameForReport = "";
          await this.loadProjectsViewList({ month_id });
          await this.loadStaffViewList({ month_id });
          this.setSelectedInterval(interval);
          await this.loadProjects(this.activeCheckboxFilter);
          this.isLoading = false;
        }
      }
    },
    async uploadReport() {
      const { since, until } = this.intervalData;
      const projectName = this.activeProjectNameForReport;
      const currentProject = this.getProjectsList.find(
        (pr) => pr.name === projectName
      );
      const pmProjectData = {
        since,
        until,
        project_id: currentProject.id,
      };
      await this.debounceUploadReport({
        pmProjectData,
        projectName: currentProject.name,
      });
    },
    async debounceUploadReport({ pmProjectData, projectName }, ms = 2000) {
      return new Promise((resolve) => {
        let currentTicket = null;

        currentTicket = setTimeout(async () => {
          if (currentTicket === this.lastTicket) {
            const report = await this.uploadPmProjectReport(pmProjectData);
            const { since, until } = pmProjectData;
            saveAs(
              report,
              `отчет по проекту - ${projectName} - ${since}-${until}.xlsx`
            );
            this.lastTicket = null;
          }
          resolve();
        }, ms);
        this.lastTicket = currentTicket;
      });
    },
    async loadProjects(currentCheckboxFilter) {
      this.isLoading = true;
      this.setActiveFilter(currentCheckboxFilter);
      if (currentCheckboxFilter === "profiles") {
        await this.loadExpensesEmployeesList({
          ...this.intervalData,
          [currentCheckboxFilter]: this.activeStaffIds.toString(),
        });
      } else if (currentCheckboxFilter === "projects") {
        await this.loadExpensesViewList({
          ...this.intervalData,
          [currentCheckboxFilter]: this.activeProjectsIds.toString(),
        });
      }

      if (!this.activeProjectsIds.length && !this.activeStaffIds.length) {
        this.activeCheckboxFilter = null;
      }
      this.isLoading = false;
    },
    setReportProjectName(project) {
      this.activeProjectNameForReport = project.name;
    },
    searchReportProjects(project) {
      this.searchingReportProject = project.toLowerCase();
    },
    ...mapActions({
      loadExpensesViewList: "expensesStore/loadExpensesViewList",
      loadExpensesEmployeesList: "expensesStore/loadExpensesEmployeesList",
      loadCalendarDays: "productionCalendarStore/loadCalendarDays",
      loadCalendarYears: "productionCalendarStore/loadCalendarYears",
      loadCalendarMonths: "productionCalendarStore/loadCalendarMonths",
      loadPlanningsList: "planningsStore/loadPlanningsList",
      loadProjectsViewList: "expensesStore/loadProjectsViewList",
      loadStaffViewList: "expensesStore/loadStaffViewList",
      uploadPmProjectReport: "reportsStore/uploadPmProjectReport",
    }),
    ...mapMutations({
      setSelectedInterval: "expensesStore/setSelectedInterval",
      setExpensesViewList: "expensesStore/setExpensesViewList",
    }),
  },
  computed: {
    ...mapState({
      planningsList: (state) => state.planningsStore.planningsList,
      expensesList: (state) => state.expensesStore.expensesList,
      error: (state) => state.expensesStore.error,
    }),
    ...mapGetters({
      getExpensesViewList: "expensesStore/getExpensesViewList",
      getCalendarYears: "productionCalendarStore/getCalendarYears",
      getCalendarMonths: "productionCalendarStore/getCalendarMonths",
      getProjectsList: "expensesStore/getProjectsList",
      getProfilesList: "expensesStore/getProfilesList",
      isInGroup: "userStore/isInGroup",
    }),
    lastPlanningDate() {
      let date;
      if (this.planningsList.length) {
        const [lastDate] = this.planningsList;
        date = dayjs(`${lastDate.year.number}-${lastDate.name}`).format(
          "YYYY-MM"
        );
      }
      return dayjs(date).format("YYYY-MM");
    },
    firstPlanningDate() {
      let date;
      if (this.planningsList.length) {
        const firstDate = this.planningsList[this.planningsList.length - 1];
        date = dayjs(`${firstDate.year.number}-${firstDate.name}`).format(
          "YYYY-MM"
        );
      }
      return dayjs(date).format("YYYY-MM");
    },
    filteredReportList() {
      let list = this.getProjectsList;
      if (this.searchingReportProject.length > 2) {
        list = list.filter(({ name: projectName }) =>
          projectName.toLowerCase().includes(this.searchingReportProject)
        );
      }
      return list;
    },
    hasDownloadAccess() {
      return this.isInGroup([ROLES.FOUNDER, ROLES.PM]);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.expenses__title {
  .expenses__title--text {
    display: inline-block;
    position: sticky;
    left: 190px;
  }
}

.expenses-view-actions {
  display: flex;
  column-gap: 250px;
  margin-right: 50px;
  margin-bottom: 25px;
}

.expenses-view-actions__left {
  display: flex;
  column-gap: 20px;
  align-items: center;
}

.expenses-view-actions__right {
  display: flex;
  column-gap: 20px;
}

.expenses-view-actions__filter {
  width: 270px;
}
</style>

<template>
  <div
    class="expenses__body-cell"
    :style="{
      left:
        numberOfColumn === 0 ? '180px' : numberOfColumn === 1 ? '480px' : '0',
    }"
    :class="{
      'expenses__body-cell--non-editable': isGeneralCell,
      'expenses__body-cell--today': isItToday,
      'expenses__body-cell--monday': getIsItMonday,
      'expenses__body-cell--workday': !isItWeekendDay,
      'expenses__body-cell--weekend-day': isItWeekendDay,
      'expenses__body-cell--last-cell': isItLastCell,
      'expenses__body-cell--sticky':
        numberOfColumn === 0 || numberOfColumn === 1,
    }"
  >
    <template>
      <div
        v-if="!this.changeTaskModeOn"
        class="expenses__body-cell-data expenses__body-cell-data--name"
        @click="cellClick"
      >
        <span class="expenses__body-cell-spoiler-icon" v-if="isOpen !== null">
          <SpoilerIcon
            :styleObject="{
              transform: isOpen ? 'rotate(0)' : 'rotate(-90deg)',
            }"
          />
        </span>
        <Tooltip
          v-if="isGeneralCell && !!data"
          :tooltipData="data"
          :tooltip-background="isEditable ? '#fff' : '#ccc'"
          :classNames="{ 'expenses__body-cell-data--text': true }"
        />
        <p v-else class="expenses__body-cell-data--text">{{ data }}</p>
        <div
          v-if="projectDescription"
          class="expenses__body-cell-data--description"
        >
          <Tooltip
            v-if="!!data"
            :tooltipData="projectDescription"
            :tooltip-background="'#fff'"
            :withIcon="true"
          />
        </div>
      </div>
      <div
        v-if="hoursRatio"
        class="expenses__body-cell-data expenses__body-cell-data--hours"
      >
        {{ hoursRatio }}
      </div>
    </template>
    <div
      v-if="numberOfColumn === 1"
      class="expenses__body-cell--sticky-border"
    ></div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import Tooltip from "./TooltipBody";
import SpoilerIcon from "@/components/icons/SpoilerIcon";
import dayjs from "dayjs";

export default {
  name: "ViewExpensesCell",
  data() {
    return {
      addCellModeOn: false,
      addCellDataInput: "",
      changeTaskModeOn: false,
      defaultTaskDataInput: this.data,
      changeTaskDataInput: this.data,
    };
  },
  components: {
    Tooltip,
    SpoilerIcon,
  },
  props: {
    data: {
      type: String,
    },
    hoursRatio: {
      type: String,
    },
    isOpen: {
      type: Boolean,
      default() {
        return null;
      },
    },
    isGeneralCell: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isEditable: {
      type: Boolean,
      default() {
        return false;
      },
    },
    taskId: {
      type: Number,
    },
    currentTimeEntriesItemId: {
      type: Number,
    },
    numberOfColumn: {
      type: Number,
      default() {
        return -2;
      },
    },
    isItWeekendDay: {
      type: Boolean,
    },
    isItToday: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isItLastCell: {
      type: Boolean,
      default() {
        return false;
      },
    },
    projectDescription: {
      type: String,
    },
    onSpoilerClick: {
      type: Function,
    },
  },
  methods: {
    ...mapMutations({
      setExpensesHiddenTasks: "expensesStore/setExpensesHiddenTasks",
    }),
    async cellClick() {
      if (this.onSpoilerClick) {
        await this.onSpoilerClick();
      }
    },
  },
  computed: {
    ...mapGetters({
      getSelectedInterval: "expensesStore/getSelectedInterval",
      getMyDate: "userStore/getMyDate",
    }),
    getDayNumber() {
      return dayjs(this.getSelectedInterval.since)
        .add(this.numberOfColumn - 2, "day")
        .date();
    },
    isSelectedIntervalIncludeCurrentDay() {
      const { since, until } = this.getSelectedInterval;
      const startDate = dayjs(since).startOf("month");
      const endDate = dayjs(until).endOf("month");
      const currentDate = this.getMyDate.toDate();
      return (
        currentDate >= startDate.toDate() && currentDate <= endDate.toDate()
      );
    },
    getWeekDay() {
      const days = ["ВС", "ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ"];
      const dayOfWeek = dayjs(this.getSelectedInterval.since, "YYYY-MM-DD")
        .add(this.numberOfColumn - 1, "day")
        .isoWeekday();
      return days[dayOfWeek - 1];
    },
    getIsItMonday() {
      return this.getWeekDay === "ПН" || this.numberOfColumn === 1;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.expenses__body-cell {
  justify-content: space-between;
  display: flex;
  padding: 2px 5px;
  position: relative;
  z-index: 8;
  border-top: 1px solid #ffffff;

  &:hover {
    .expenses__body-cell-timer-icon {
      visibility: visible;
    }
  }
}

.expenses__body-cell-data--description {
  margin-left: 3px;
  line-height: 0;
}

.expenses__body-cell--non-editable {
  .expenses__body-cell-data--name {
    height: 20px;
    width: 50px;
    flex-basis: 100%;

    .expenses__body-cell-data--text {
      overflow: hidden;
    }
  }

  .expenses__body-cell-data--hours {
    white-space: pre;
  }
}

.expenses__body-cell--non-editable {
  .expenses__body-cell-data--name {
    cursor: pointer;
  }
}

.expenses__body-cell--non-editable {
  background-color: var(--non-editable-bg-color);
}

.expenses__body-cell {
  &.expenses__body-cell--last-cell {
    border-right: 1px solid #ffffff;
  }

  &.expenses__body-cell--workday:before {
    width: 1px;
    height: calc(100% + 2px);
    background-color: #efefef;
    position: absolute;
    top: -1px;
    left: -1px;
    content: "";
    display: block;
  }

  &.expenses__body-cell--weekend-day {
    background-color: #f3f3f3;
  }

  &.expenses__body-cell--monday:before,
  &.expenses__body-cell--today:before {
    width: 1px;
    height: calc(100% + 2px);
    position: absolute;
    top: -1px;
    content: "";
    display: block;
  }

  &.expenses__body-cell--monday:before {
    background-color: #999999;
    width: 1px;
    left: -1px;
  }

  &.expenses__body-cell--today:before {
    background-color: #1981c9;
    left: 1px;
    z-index: 1;
  }
}

.expenses__body-cell-data--input {
  padding: 0;
  border: none;
  max-width: 100%;
  text-align: center;
  position: absolute;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  top: 1px;
  left: 1px;
}

.expenses__body-cell-data--name {
  display: flex;
  align-items: center;

  .expenses__body-cell-spoiler-icon {
    transform: rotate(0);
    margin-right: 2px;
    transition: all 2s ease-in-out;
    line-height: 0;
  }
}

.expenses__icons {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.expenses__icons-eye-wrapper,
.expenses__icons-trash-wrapper {
  height: 17px;
}

.expenses__icons-trash-wrapper {
  width: 15px;
}

.expenses__body-cell .expenses__body-cell-data--form {
  height: 17px;
  margin-left: 0;
  display: flex;
  width: 100%;

  .expenses__body-cell-data-add--input {
    text-align: start;
    border: 1px solid gray;
    border-radius: 4px;
    width: 100%;
    padding-left: 4px;
  }

  .expenses__body-cell-data--buttons {
    display: flex;
    justify-content: space-between;
    margin-left: 8px;

    .expenses__body-cell-data--buttons-item {
      cursor: pointer;
      padding: 0 4px;
      font-size: 14px;
    }

    .expenses__body-cell-data--buttons-item:first-child {
      margin-right: 4px;
    }
  }
}

.expenses__body-cell-data--weekend-day {
  background-color: #f3f3f3;
}

.expenses__body-cell-data--too-much-tracked {
  background-color: #e9736e;
  color: #fff;
}

.expenses__body-cell-data--not-allowed {
  width: 49px;
  height: 21px;
  position: absolute;
  left: 0;
  top: 0;

  .expenses__body-cell-data--not-allowed-hatch {
    position: absolute;
    left: 0;
  }
}

.expenses__body-cell--sticky {
  position: sticky;
  z-index: 9;
  background-color: #fff;
}

.expenses__body-cell--sticky-border:after {
  content: "";
  display: block;
  position: absolute;
  right: -1px;
  top: -1px;
  height: calc(100% + 2px);
  width: 1px;
  background-color: #999999;
}

.expenses__body-cell-timer-icon,
.expenses__body-cell-restore-icon {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 4px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: default;

  svg {
    width: 100%;
    height: 100%;
  }

  button,
  .tooltip {
    width: 100%;
  }
}

.expenses__body-cell-restore-icon {
  visibility: visible;
}

.expenses__body-cell-timer-icon--visible {
  visibility: visible;
}
</style>
